var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.basisshow, title: "单据维护", center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.basisshow = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    inline: "",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户ID：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "客户ID", disabled: "" },
                        model: {
                          value: _vm.form.info.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.info, "id", $$v)
                          },
                          expression: "form.info.id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业名称：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "企业名称", disabled: "" },
                        model: {
                          value: _vm.form.info.dealer_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.info, "dealer_name", $$v)
                          },
                          expression: "form.info.dealer_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "联系人", disabled: "" },
                        model: {
                          value: _vm.form.info.boss,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.info, "boss", $$v)
                          },
                          expression: "form.info.boss",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "电话", disabled: "" },
                        model: {
                          value: _vm.form.info.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.info, "mobile", $$v)
                          },
                          expression: "form.info.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  attrs: { stripe: "", data: _vm.form.list },
                  on: {
                    "selection-change": _vm.handleSelectionChange,
                    "row-click": _vm.handlercolumn,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "80", align: "center", type: "selection" },
                  }),
                  _vm._l(_vm.colemd, function (list, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: list.label,
                        align: list.align,
                        prop: list.prop,
                        width: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          list.label == "数量"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { cursor: "pointer" } },
                                      [_vm._v(_vm._s(row.num))]
                                    ),
                                  ]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                ],
                2
              ),
              _c("br"),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerdelete },
                },
                [_vm._v(" 清空选中类型单据全部数据 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.basisshow = !_vm.basisshow
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("admin-dataorder", { ref: "admin" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }